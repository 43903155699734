/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { withRouter } from 'react-router-dom';

import { rbac } from 'common/rbac';
import { useSettings } from 'common/hooks';

import NavSection from './nav-section';

const Nav = withRouter(props => {
  const { private_cvs, disabled_features } = useSettings();
  const currentPath = props.location.pathname;
  const elements = [
    {
      title: 'Primary',
      items: [
        {
          text: 'Hours',
          url: '/hours',
          icon: 'hours',
          rbac: rbac.HOURS_READ
        },
        {
          text: 'Profile',
          url: '/profile',
          icon: 'profile',
          rbac: rbac.EMPLOYEE_PROFILE_READ
        }
      ]
    },
    {
      title: 'Competency',
      items: [
        {
          text: 'CV',
          url: '/cv',
          icon: 'cv',
          rbac: rbac.CV_READ
        },
        {
          text: 'Skills',
          url: '/skills',
          icon: 'code',
          rbac: rbac.EMPLOYEE_PROFILE_READ
        }
      ]
    },
    {
      title: disabled_features?.includes('Projects') ? 'Offers' : 'Projects',
      showTitle: true,
      items: [
        {
          text: 'Projects',
          url: '/projects',
          icon: 'projects',
          rbac: rbac.PROJECTS_READ
        },
        {
          text: 'Deals',
          url: '/deals',
          icon: 'offers',
          rbac: rbac.DEALS_READ
        },
        {
          text: 'Offers',
          url: '/offers',
          icon: 'offers',
          rbac: rbac.OFFERS_READ
        },
        {
          text: 'Reports',
          url: '/reports/billing',
          icon: 'reports',
          rbac: rbac.REPORTS_READ
        }
      ]
    },
    {
      title: 'Employees',
      showTitle: true,
      items: [
        {
          text: 'List',
          url: '/employees/list',
          icon: 'list',
          rbac: private_cvs ? rbac.EMPLOYEE_CV_WRITE : rbac.EMPLOYEES_READ
        },
        {
          text: 'Summary',
          url: '/employees/summary',
          icon: 'calendar',
          rbac: rbac.EMPLOYEES_SUMMARY_READ
        },
        {
          text: 'Resourcing',
          url: '/resourcing',
          icon: 'activity',
          rbac: rbac.RESOURCING_READ
        },
        {
          text: 'External',
          url: '/employees/external',
          icon: 'external',
          rbac: rbac.EXTERNALS_READ
        },
        {
          text: 'Logs',
          url: '/logs/employees',
          icon: 'list',
          rbac: rbac.LOGS_EMPLOYEES_READ
        }
      ]
    },
    {
      title: 'Search',
      items: [
        {
          text: 'Search',
          url: '/search',
          icon: 'search',
          rbac: rbac.GLOBAL_SEARCH
        }
      ]
    },
    {
      title: 'other',
      items: [
        {
          text: 'Settings',
          url: '/settings',
          icon: 'settings',
          rbac: rbac.SETTINGS_READ
        },

        {
          text: 'Guide',
          url: '/guide',
          icon: 'guide',
          rbac: rbac.GUIDE_READ
        }
        // {
        //   text: 'Improve Bifa',
        //   url: '/ideas',
        //   icon: 'emoji_objects',
        //   rbac: rbac.IDEAS_READ
        // }
      ]
    }
  ];

  return (
    <div>
      {elements.map(section => {
        return (
          <NavSection
            key={section.title}
            section={section}
            currentPath={currentPath}
          />
        );
      })}
    </div>
  );
});

export default Nav;
