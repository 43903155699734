import { types, flow, getParent } from 'mobx-state-tree';
import axios from 'common/utils/axios';

import { LazyEmployee, MomentType } from '../types';
import Traits from './traits-model';
import Language from './language-model';
import WorkHistory from './workhistory-model';
import Certificate from './certificate-model';

const CvData = types
  .model('CvData', {
    description: types.optional(types.string, ''),
    experiences: types.optional(Traits, () => Traits.create([])),
    skills: types.optional(Traits, () => Traits.create([])),
    certificates: types.optional(types.array(Certificate), []),
    education: types.optional(types.array(Certificate), []),
    languages: types.array(Language),
    work_history: types.optional(WorkHistory, () => WorkHistory.create([])),
    updated_at: types.maybeNull(MomentType),
    state: 'ready'
  })
  .views(() => ({
    isReady() {
      return true; // Fake for editor
    }
  }))
  .actions(() => ({
    load() {} // Fake for editor
  }));

const CvVersions = types
  .model('CvVersions', {
    id: types.identifierNumber,
    versions: types.array(
      types.model({
        author: types.maybeNull(LazyEmployee),
        data: CvData
      })
    ),
    state: types.maybeNull(
      types.enumeration(['pending', 'ready', 'error']),
      'pending'
    )
  })
  .views(self => ({
    get list() {
      return [...self.versions];
    }
  }))
  .actions(self => ({
    load: flow(function* load() {
      if (self.state === 'ready') {
        return;
      }

      const mapVersion = ({ author, data, updated_at }) => ({
        author,
        data: { ...data, updated_at }
      });

      const parent = getParent(self);
      try {
        const { data } = yield axios.get(
          `/api/v1/employees/${parent.id}/cvs/${self.id}/versions`
        );
        self.versions = data.map(mapVersion); // eslint-disable-line no-param-reassign
        self.setState('ready');
      } catch (error) {
        console.error('Failed to load cv versions', error); // eslint-disable-line no-console
        self.setState('error');
      }
    }),
    setState(state) {
      self.state = state; // eslint-disable-line no-param-reassign
    }
  }));

export default CvVersions;
