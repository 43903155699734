import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { DebounceInput } from 'react-debounce-input';

import axios from 'common/utils/axios';
import useClickOutside from 'common/hooks/useClickOutside';

import { SimpleInput, Button } from 'components/atoms';
import { ListAvatar } from 'components/molecules';

import {
  Container,
  EmployeeContainer,
  EmployeeAction,
  SearchContainer,
  EmployeeList,
  EmployeeWrapper
} from './employee-search-style';

const search = (value, options) => {
  const params = { ...options, name: value };
  return axios.get('/api/v1/employees/search', { params });
};

const Employee = observer(({ employee, ...rest }) => (
  <ListAvatar
    tabIndex='-1'
    name={employee.fullName ? employee.fullName() : employee.full_name}
    linkPath='#'
    {...employee}
    {...rest}
  />
));

const values = {
  ArrowUp: -1,
  ArrowDown: 1,
  Tab: 0
};

const preventDefaultIfNecessary = (event, isLast) => {
  const isArrowKey = ['ArrowUp', 'ArrowDown'].includes(event.key);
  if (isArrowKey || (event.key === 'Tab' && isLast && !event.shiftKey)) {
    event.preventDefault();
  }
};

const EmployeeSearch = observer(
  ({
    employee,
    onSelect,
    reset,
    filtered = [],
    show = 5,
    options = {},
    ...rest
  }) => {
    const [data, setData] = useState([]);
    const [query, setQuery] = useState('');
    const [hide, setHide] = useState(true);

    const setFilteredData = items => {
      setData(items.filter(item => !filtered.includes(item.id)).slice(0, show));
    };

    useEffect(() => {
      if (query) {
        search(query, options).then(response => setFilteredData(response.data));
      } else {
        setData([]);
      }
    }, [query]);

    useEffect(() => {
      setQuery('');
    }, [employee]);

    const ref = useRef(null);
    useClickOutside(ref, () => setHide(true));

    const list = useRef(null);

    const handleQuickSelect = (event, item) => {
      if (event.key === 'Enter' && item) {
        onSelect(item);
      }
    };

    const handleFocus = e => {
      if (['ArrowUp', 'ArrowDown', 'Tab'].includes(e.key)) {
        const elements = [...list.current.children];
        const lastIndex = elements.length - 1;
        const current = elements.findIndex(el => document.activeElement === el);

        const index = Math.min(lastIndex, Math.max(0, current + values[e.key]));
        elements[index].focus();

        preventDefaultIfNecessary(e, index === lastIndex);
      }
    };

    return (
      <Container>
        {employee ? (
          <EmployeeContainer>
            <Employee employee={employee} small />
            {reset && (
              <EmployeeAction
                as={Button}
                icon='cross2'
                small
                transparent
                onClick={reset}
              />
            )}
          </EmployeeContainer>
        ) : (
          <SearchContainer
            onFocus={() => setHide(false)}
            onKeyDown={handleFocus}
            ref={ref}
          >
            <SimpleInput
              as={DebounceInput}
              value={query}
              onChange={setQuery}
              debounceTimeout={300}
              onKeyDown={e => handleQuickSelect(e, data[0])}
              autoFocus
              icon='search'
              {...rest}
            />
            {!hide && data.length ? (
              <EmployeeList ref={list}>
                {data.map(item => (
                  <EmployeeWrapper
                    key={item.id}
                    tabIndex='0'
                    onClick={() => onSelect(item)}
                    onKeyDown={e => handleQuickSelect(e, item)}
                  >
                    <Employee employee={item} />
                  </EmployeeWrapper>
                ))}
              </EmployeeList>
            ) : null}
          </SearchContainer>
        )}
      </Container>
    );
  }
);

export default EmployeeSearch;
