import { types, flow, resolveIdentifier, getSnapshot } from 'mobx-state-tree';
import { observable } from 'mobx';
import moment from 'moment';

import axios from '../common/utils/axios';
import { Offer } from './offer-model';

const batchQueue = observable.array();

export const OffersList = types
  .model('OfferList', {
    offers: types.map(Offer),
    state: types.maybeNull(
      types.enumeration(['pending', 'ready', 'error']),
      'pending'
    )
  })
  .views(self => ({
    inMonth(month) {
      const selectedRange = moment.range(
        moment(month).startOf('month'),
        moment(month).endOf('month')
      );
      const offersFiltered = [...self.offers.values()].filter(o => {
        const offerRange = moment.range(
          moment(o.start).startOf('day'),
          moment(o.expires).endOf('day')
        );
        return offerRange.overlaps(selectedRange);
      });
      return offersFiltered;
    },
    all() {
      return [...self.offers.values()];
    },
    allOtherActive(id) {
      return [...self.offers.values()].filter(
        o => o.id !== id && moment().isBefore(o.expires)
      );
    },
    isLoaded() {
      return self.state === 'ready';
    }
  }))
  .actions(self => ({
    createOffer(o) {
      const existing = self.offers.get(o.id);
      if (!existing) {
        const offer = Offer.create({ ...o });
        self.offers.put(offer);
      }
    },
    load: flow(function* load() {
      if (!self.isLoaded()) {
        try {
          const { data } = yield axios.get('/api/v1/offers');
          data.map(o => self.createOffer(o));
          self.state = 'ready'; // eslint-disable-line no-param-reassign
        } catch (error) {
          console.log('Error loading offer list', error); // eslint-disable-line no-console
        }
      }
      return self;
    }),
    loadOffer(id, batch = false) {
      const offer = resolveIdentifier(Offer, self, id);
      if (offer) {
        return offer;
      }
      const o = Offer.create({ id });
      if (batch) {
        batchQueue.push(o.id);
      } else {
        o.load(id);
      }
      self.offers.put(o);
      return o;
    },
    addOffer: flow(function* addOffer(o) {
      const offer = getSnapshot(o);
      const { data } = yield axios.post(
        '/api/v1/offers',
        JSON.stringify({ offer })
      );
      self.createOffer(data);
      return data;
    }),
    deleteOffer: flow(function* deleteOffer(id) {
      self.offers.delete(id);
      yield axios.delete(`/api/v1/offers/${id}`);
    })
  }));

export const sortByName = offers =>
  offers.sort((a, b) => a.name.localeCompare(b.name));

export default OffersList;
