import styled from 'styled-components/macro';

import { breakpointsRefresh as breakpoints } from 'common/styles';

import { IconContainer } from '../icon/icon';

export const BasicButton = styled.button`
  color: ${({ color }) => color || 'var(--color-text-darker)'};
  background-color: ${({ bgColor }) =>
    bgColor || 'var(--color-background-is-active)'};
  padding: 0.25em 0.75em;
  cursor: pointer;
  text-decoration: none;

  border: none;
  border-radius: var(--border-radius-md);

  font-size: inherit;
  white-space: pre;

  &:hover {
    background-color: ${({ bgHoverColor }) =>
      bgHoverColor || 'var(--color-background-hint)'};
    color: var(--color-text-darker);
  }

  ${({ float }) => float && `float: ${float};`}

  ${({ transparent }) => transparent && 'background-color: transparent;'}

  ${breakpoints.md} {
    padding: 0.35em 0.75em;
  }
  transition: background-color 0.2s, color 0.2s;

  &:disabled,
  &:disabled:hover {
    cursor: default;
    color: ${props =>
      props.small ? 'var(--color-text-light)' : 'var(--color-text-lighter)'};
    background-color: ${props =>
      props.small ? 'transparent' : 'var(--color-background-hint)'};
border: 1px solid var(--color-background-hint);
    ${IconContainer} {
      color: ${props =>
        props.small ? 'var(--color-text)' : 'var(--color-text-light)'};
    }
  }
  &:active,
  &:focus,
  &:hover {
    outline: 0;
    text-decoration: none;
  }

  ${IconContainer} {
    vertical-align: middle;
  }

  // & + & {
  //   margin-left: 1rem;
  // }
`;

export const LabelContainer = styled.span`
  padding-left: 0.5em;
  padding-right: 0.5em;
  vertical-align: middle;
`;
