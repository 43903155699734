import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { observer } from 'mobx-react';

import { Button, Icon } from 'components/atoms';

import {
  BottomBarContainer,
  BottomBarInfo,
  BottomBarError,
  BottomBarActions
} from './bottom-bar-style';

const BottomBar = observer(
  ({
    info,
    error,
    onConfirm,
    onCancel,
    confirmIcon,
    confirmLabel,
    cancelLabel,
    doubleCheckCancel,
    fixed,
    visible = true
  }) => {
    const { ref, inView } = useInView({ threshold: 1 });
    const [canceling, setCanceling] = useState(false);

    const handleCancel = () =>
      doubleCheckCancel ? setCanceling(true) : onCancel();

    const handleConfirmCancel = () => {
      setCanceling(false);
      onCancel();
    };

    useEffect(() => {
      setCanceling(false);
    }, [visible]);

    return (
      <>
        <BottomBarContainer inView={inView} visible={visible} fixed={fixed}>
          <BottomBarActions>
            {canceling ? (
              <>
                <Button
                  color='var(--color-text-lightest)'
                  bgColor='var(--color-error)'
                  bgHoverColor='var(--color-error-light)'
                  onClick={handleConfirmCancel}
                >
                  Yes
                </Button>
                <Button transparent onClick={() => setCanceling(false)}>
                  No
                </Button>
              </>
            ) : (
              <>
                <Button
                  icon={confirmIcon}
                  onClick={onConfirm}
                  color='var(--color-text-lightest)'
                  bgColor='var(--color-primary)'
                  bgHoverColor='var(--color-primary-lightest)'
                >
                  {confirmLabel || 'Save'}
                </Button>
                <Button transparent onClick={handleCancel}>
                  {cancelLabel || 'Cancel'}
                </Button>
              </>
            )}
          </BottomBarActions>
          {error ? (
            <BottomBarError>
              <Icon
                name='exclamationTriangleFilled'
                color='#fff'
                fill='var(--color-error-dark)'
              />
              {error}
            </BottomBarError>
          ) : (
            <BottomBarInfo>
              {canceling
                ? 'Are you sure you want to discard unsaved changes?'
                : info}
            </BottomBarInfo>
          )}
        </BottomBarContainer>
        {/* Sentinel element to detect visibility of sticky bar's actual location */}
        <div ref={ref} />
      </>
    );
  }
);

export default BottomBar;
