import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import styled from 'styled-components/macro';

import { BrowserRouter as Router, Switch } from 'react-router-dom';

import {
  DerpContextProvider,
  DateContextProvider,
  AuthProvider,
  SyncContextProvider,
  ViewportContextProvider,
  UiContextProvider,
  IntlContextProvider
} from 'contexts';

import { AdminRoute, AuthRoute as Route } from 'common/routes';

import holidayStore from 'stores/holidayStore';
import notificationStore from 'stores/notificationStore';

import { GlobalStyle } from 'common/styles';

import { Derp } from 'models/derp-model';
import { EmployeeList as Employees } from 'models/employee-list-model';
import { ProjectsList } from 'models/projects-list-model';
import { AssignmentsList } from 'models/assignment-list-model';
import { TimeEntriesList } from 'models/time-entries-list-model';
import { OffersList } from 'models/offer-list-model';
import { SurveyList } from 'models/survey-list-model';
import { ProjectEstimates } from 'models/project-estimates-model';
import { TagList } from 'models/tag-list-model';
import { DealList } from 'models/deal-list-model';
import { ResourceList } from 'models/resourcing-list-model';
import { NotificationList } from 'models/notification-list-model';
import { CustomerList } from 'models/customer-list-model';

// TODO: Proper typing once migration to TS is further along
const env = {};
const employees = Employees.create({}, env);
const projects: any = ProjectsList.create({}, env);
const assignments: any = AssignmentsList.create({}, env);
const timeentries: any = TimeEntriesList.create({}, env);
const offers: any = OffersList.create({}, env);
const surveys: any = SurveyList.create({}, env);
const resources: any = ResourceList.create({}, env);
const estimates: any = ProjectEstimates.create({}, env);
const tags: any = TagList.create({}, env);
const deals: any = DealList.create({}, env);
const notifications: any = NotificationList.create({}, env);
const customers: any = CustomerList.create({}, env);
const derp = Derp.create(
  {
    assignments,
    timeentries,
    projects,
    employees,
    offers,
    surveys,
    resources,
    estimates,
    tags,
    deals,
    notifications,
    customers
  },
  env
);

const stores = {
  holidayStore,
  derp,
  notificationStore
};

const Loading = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HoursView = lazy(() => import('pages/hours/hours'));
const HoursOverviewView = lazy(() => import('pages/hours/hours-overview'));
const ProfileView = lazy(() => import('pages/profile/profile-view'));
const CvView = lazy(() => import('pages/cv/cv-view'));
const GuideView = lazy(() => import('pages/guide/guide'));
const ProjectListView = lazy(() => import('pages/projects/project-list-view'));
const ProjectView = lazy(() => import('pages/projects/project-view'));
const EmployeesView = lazy(() => import('pages/employees/employees-view'));
const EmployeeView = lazy(() => import('pages/employees/employee-view'));
const DealListView = lazy(() => import('pages/deals/deals-list-view'));
const DealView = lazy(() => import('pages/deals/deal-view'));
const OfferListView = lazy(() => import('pages/offers/offer-list-view'));
const OfferView = lazy(() => import('pages/offers/offer-view'));
const OfferCvView = lazy(() => import('pages/offers/offer-cv-view'));
const ReportsView = lazy(() => import('pages/reports/reports-view'));
const SettingsView = lazy(() => import('pages/settings/settings-view'));
const SearchView = lazy(() => import('pages/search/search-view'));
const ResourcingView = lazy(() => import('pages/resourcing/resourcing-view'));
const LogsView = lazy(() => import('pages/logs/logs-view'));
const CustomerView = lazy(() => import('pages/customers/customer-view'));
const SkillsView = lazy(() => import('pages/skills/skills-view'));

const refresh = document.querySelector('#refresh');

const App = () => {
  return (
    <main>
      <GlobalStyle />
      <Router>
        <Suspense fallback={<Loading>Loading...</Loading>}>
          <ViewportContextProvider>
            <UiContextProvider>
              <Provider {...stores}>
                <DerpContextProvider derp={stores.derp}>
                  <AuthProvider derp={stores.derp}>
                    <DateContextProvider>
                      <IntlContextProvider>
                        <SyncContextProvider>
                          <Switch>
                            {/* For redirections */}
                            <Route exact path='/'>
                              <div />
                            </Route>
                            <Route path='/refresh'>
                              <HoursView />
                            </Route>
                            <Route path='/hours'>
                              <HoursView />
                            </Route>
                            <Route path='/overview'>
                              <HoursOverviewView />
                            </Route>
                            <Route path='/cv'>
                              <CvView />
                            </Route>
                            <Route path='/skills'>
                              <SkillsView />
                            </Route>
                            <Route path='/profile'>
                              <ProfileView />
                            </Route>
                            <Route path='/projects/estimates'>
                              <ProjectListView />
                            </Route>
                            <Route path='/projects/:project_id'>
                              <ProjectView />
                            </Route>
                            <Route path='/projects'>
                              <ProjectListView />
                            </Route>
                            <Route path='/deals/:deal_id'>
                              <DealView />
                            </Route>
                            <Route path='/deals'>
                              <DealListView />
                            </Route>
                            <Route path='/offers/:offer_id/:employee_id'>
                              <OfferCvView />
                            </Route>
                            <Route path='/offers/:offer_id'>
                              <OfferView />
                            </Route>
                            <Route path='/offers'>
                              <OfferListView />
                            </Route>
                            <AdminRoute path='/reports/:tab' redirect='/hours'>
                              <ReportsView />
                            </AdminRoute>
                            <Route path='/employees/:tab(list|profiles|summary|timeline|external|archive|external_archive)'>
                              <EmployeesView />
                            </Route>
                            <Route path='/logs'>
                              <LogsView />
                            </Route>
                            <Route path='/employees/:employee_id'>
                              <EmployeeView />
                            </Route>
                            <Route path='/search'>
                              <SearchView />
                            </Route>
                            <Route path='/settings'>
                              <SettingsView />
                            </Route>
                            <Route path='/guide'>
                              <GuideView />
                            </Route>
                            <Route path='/resourcing'>
                              <ResourcingView />
                            </Route>
                            <Route path='/customers/:customer_id'>
                              <CustomerView />
                            </Route>
                            <Route exact path='/login'>
                              <div />
                            </Route>
                            <Route>
                              <HoursView />
                            </Route>
                          </Switch>
                        </SyncContextProvider>
                      </IntlContextProvider>
                    </DateContextProvider>
                  </AuthProvider>
                </DerpContextProvider>
              </Provider>
            </UiContextProvider>
          </ViewportContextProvider>
        </Suspense>
      </Router>
    </main>
  );
};

ReactDOM.render(<App />, refresh);
