import React, { useRef, useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ViewportContext, AuthContext } from 'contexts';
import { Portal } from 'common/hooks';
import { canAccess } from 'common/rbac';

import { Icon } from 'components/atoms';

import {
  ActionMenuBar,
  ActionMenuList,
  Button,
  Label,
  IconContainer,
  StyledPopup,
  ActionMenuToggle,
  DropdownTrigger,
  MobileItem
} from './action-menu-style';

// TODO: Refactor and use this for menu maybe..
export const ActionDropdown = observer(
  ({
    items,
    icon,
    iconColor,
    iconSize,
    anchor,
    disabled,
    triggerLabel = ''
  }) => {
    const popupRef = useRef();
    const history = useHistory();

    const handleClick = item => {
      if (item.link) {
        history.push(item.link);
      }
      if (item.action) {
        item.action();
      }
      if (popupRef.current) {
        popupRef.current.close();
      }
    };

    return (
      <StyledPopup
        closeOnEscape
        closeOnDocumentClick
        position={anchor || 'bottom right'}
        contentStyle={false}
        ref={popupRef}
        trigger={
          <DropdownTrigger transparent disabled={disabled}>
            <Icon
              fill={iconColor}
              size={iconSize}
              name={icon || 'dotsVertical'}
            />
            {triggerLabel}
          </DropdownTrigger>
        }
      >
        <ActionMenuList>
          {items.map(item => (
            <MobileItem
              key={item.label}
              onClick={() => handleClick(item)}
              className={item.disabled ? 'is-disabled' : ''}
            >
              <Label color={item.color}>{item.label}</Label>
              <IconContainer color={item.color}>
                <Icon fill={item.iconColor} name={item.icon} />
              </IconContainer>
            </MobileItem>
          ))}
        </ActionMenuList>
      </StyledPopup>
    );
  }
);

const ActionMenuContent = observer(
  ({ items, portalBreakpoint, maxVisible = 3 }) => {
    const popupRef = useRef();
    const history = useHistory();

    const handleClick = item => {
      if (item.link) {
        history.push(item.link);
      }
      if (item.action) {
        item.action();
      }
      if (popupRef.current) {
        popupRef.current.close();
      }
    };

    const itemsShown =
      items.length <= maxVisible ? items : items.slice(0, maxVisible - 1);
    const itemsHidden =
      items.length <= maxVisible ? null : items.slice(maxVisible - 1);
    const dropdownItems = portalBreakpoint ? itemsHidden : items;

    const Dropdown = ({ mobilePopup }) => {
      return (
        <>
          <StyledPopup
            closeOnEscape
            closeOnDocumentClick
            position='bottom right'
            arrow={false}
            contentStyle={false}
            ref={popupRef}
            mobilePopup={mobilePopup}
            trigger={
              portalBreakpoint ? (
                <Button variant='outlined'>
                  <Icon name='dotsHorizontal' />
                </Button>
              ) : (
                <ActionMenuToggle>
                  <Icon
                    name={
                      dropdownItems.length === 1
                        ? items[0].icon
                        : 'dotsHorizontal'
                    }
                  />
                </ActionMenuToggle>
              )
            }
          >
            <ActionMenuList>
              {dropdownItems.map(item => (
                <MobileItem
                  key={item.label}
                  onClick={() => handleClick(item)}
                  className={item.disabled ? 'is-disabled' : ''}
                >
                  <Label color={item.color}>{item.label}</Label>
                  <IconContainer color={item.color}>
                    <Icon name={item.icon} fill={item.iconColor} />
                  </IconContainer>
                </MobileItem>
              ))}
            </ActionMenuList>
          </StyledPopup>
        </>
      );
    };

    return (
      <>
        {portalBreakpoint ? (
          <ActionMenuBar>
            {itemsShown.map(item => (
              <Button
                key={item.label}
                onClick={() => handleClick(item)}
                disabled={item.disabled}
              >
                <Icon name={item.icon} />
                <Label>{item.label}</Label>
              </Button>
            ))}
            {itemsHidden && <Dropdown />}
          </ActionMenuBar>
        ) : (
          <Dropdown mobilePopup />
        )}
      </>
    );
  }
);

const ActionMenu = observer(({ items, portalBreakpoint, maxVisible }) => {
  const { breakpoint } = useContext(ViewportContext);
  const { user } = useContext(AuthContext);
  const [allowedItems, setAllowedItems] = useState([]);
  const [alwaysInPortal, setAlwaysInPortal] = useState([]);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const width = portalBreakpoint;

  const _breakpoint =
    typeof portalBreakpoint === 'boolean'
      ? portalBreakpoint
      : breakpoint[width];

  useEffect(() => {
    const allowed = items.filter(
      item => !item.rbac || canAccess(user.role, item.rbac, item.dynamic || {})
    );
    setAllowedItems(allowed);
    if (_breakpoint) {
      setAlwaysInPortal(allowed.filter(item => item.alwaysInPortal));
      setItemsFiltered(allowed.filter(item => !item.alwaysInPortal));
    }
  }, [items]);

  return (
    allowedItems.length > 0 && (
      <>
        {_breakpoint ? (
          <>
            <ActionMenuContent
              items={itemsFiltered}
              portalBreakpoint={_breakpoint}
              maxVisible={maxVisible}
            />
            {alwaysInPortal.length > 0 && (
              <Portal target='action-menu-mobile-portal'>
                <ActionMenuContent
                  items={
                    alwaysInPortal.length > 0 ? alwaysInPortal : itemsFiltered
                  }
                />
              </Portal>
            )}
          </>
        ) : (
          <Portal target='action-menu-mobile-portal'>
            <ActionMenuContent items={allowedItems} />
          </Portal>
        )}
      </>
    )
  );
});
ActionMenu.defaultProps = {
  portalBreakpoint: 'lg',
  maxVisible: 3
};

ActionMenu.propTypes = {
  items: PropTypes.array.isRequired,
  maxVisible: PropTypes.number,
  portalBreakpoint: PropTypes.oneOf([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'xxl',
    // Always expanded
    true,
    // Always collapsed
    false
  ])
};
export default ActionMenu;
