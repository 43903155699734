import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';

import { SimpleInput } from 'components/atoms';
import { Label } from 'common/styles/refresh';
import { usePermission, rbac } from 'common/rbac';
import { DerpContext } from 'contexts';

import { Tag } from 'components/molecules';

import {
  Container,
  CategorySection,
  CategoryTitle,
  CategoryTags,
  SearchInput
} from './tag-list-style';

const TagList = observer(
  ({ editable, deletable, model, used, searchable, ...rest }) => {
    const { tags } = useContext(DerpContext);
    const [filtering, setFiltering] = useState('');

    const isTaggable =
      model && [model.loadTags, model.hasTag, model.toggleTag].every(Boolean);

    usePermission(rbac.TAGS_READ, () => {
      tags.load(used);
      if (isTaggable) model.loadTags();
    });

    if (Boolean(model) && !isTaggable) {
      return null;
    }

    const groupedTags = tags.filteredAndGroupedByCategory(filtering);

    return (
      <Container {...rest}>
        {searchable && (
          <SimpleInput
            as={SearchInput}
            onChange={setFiltering}
            defaultValue={filtering}
            icon='search'
            placeholder='Filter tags...'
          />
        )}
        {Object.entries(groupedTags).map(([category, list]) => (
          <CategorySection key={category}>
            <CategoryTitle>{category}</CategoryTitle>
            <CategoryTags>
              {list.map(tag => (
                <Tag
                  key={tag.id}
                  editable={editable}
                  deletable={deletable}
                  model={isTaggable && model}
                  tag={tag}
                  small
                />
              ))}
            </CategoryTags>
          </CategorySection>
        ))}
        {Object.keys(groupedTags).length === 0 && (
          <Label disabled large>
            No tags
          </Label>
        )}
      </Container>
    );
  }
);

export default TagList;
