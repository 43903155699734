import styled, { css } from 'styled-components/macro';

import { breakpointsRefresh as breakpoints } from 'common/styles';
import { IconContainer } from 'components/atoms/icon/icon';

export const BottomBarContainer = styled.div`
  --margin: calc(-0.7 * var(--sideMargin));
  --padding: calc(0.5 * var(--sideMargin));
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      bottom: -1px;

      ${breakpoints.md} {
        padding-left: calc(13rem + var(--sideMargin)); // Derived from Sidebar
      }
    `}

  display: flex;
  align-items: center;
  padding: 0 var(--padding);
  margin-top: 2rem;
  margin-left: var(--margin);
  margin-right: var(--margin);

  border-radius: var(--border-radius-xxl);
  border-top: 1px solid transparent;

  height: calc(2.5 * var(--sideMargin));
  background-color: var(--color-modal);

  box-shadow: var(--shadow-md-reverse);
  z-index: 50000;

  opacity: 1;
  transform: translateY(0);
  transition: all 200ms var(--ease-in-out-expo);

  ${({ inView }) =>
    inView &&
    css`
      box-shadow: none;
      border-radius: 0;
      border-color: var(--color-text-lightest);
    `}

  ${({ visible }) =>
    !visible &&
    css`
      pointer-events: none;
      opacity: 0;
      transform: translateY(30%);
    `}
`;

export const BottomBarInfo = styled.div`
  flex: 1;
  font-weight: 400;
  color: var(--color-text-darker);
  text-align: center;
`;

export const BottomBarError = styled(BottomBarInfo)`
  color: var(--color-error-dark);

  ${IconContainer} {
    vertical-align: middle;
    margin-right: 0.5rem;
  }
`;

export const BottomBarActions = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;

  > * {
    margin-left: 0.5rem;
  }
`;
