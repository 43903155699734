import styled, { css } from 'styled-components/macro';
import { breakpointsRefresh } from 'common/styles';

const breakpoints = breakpointsRefresh;

const handleColumns = columns => {
  return columns.map(column => column.width || '1fr').join(' ');
};
const handleJustify = justify => {
  switch (true) {
    case justify === 'center':
      return 'center';
    case justify === 'right':
      return 'flex-end';
    case justify === 'left':
      return 'flex-start';
    default:
      return 'flex-start';
  }
};

const handleVerticalAlign = verticalAlign => {
  switch (true) {
    case verticalAlign === 'full':
    case verticalAlign === 'stretch':
      return 'stretch';
    case verticalAlign === 'top':
      return 'flex-start';
    case verticalAlign === 'bottom':
      return 'flex-end';
    default:
      return 'center';
  }
};

// TODO: Header sticky doesn't currently work with the customer project sticky
const handleStickyPositioning = ({ overflow, header }) => {
  if (typeof overflow !== 'object') return null;

  // if (overflow.top && header) {
  //   return css`
  //     position: sticky;
  //     top: 0;

  //     background: var(--color-background);

  //     z-index: 1;

  //     &:first-child {
  //       left: ${overflow.left && 0};
  //       z-index: ${overflow.left && 2};
  //     }

  //     &:last-child {
  //       right: ${overflow.right && 0};
  //     }
  //   `;
  // }

  return css`
    &:first-child {
      position: sticky;
      left: ${overflow.left && 0};
      background: var(--color-background);
      z-index: 1;
    }
    &:nth-last-child(${header ? 1 : 2}) {
      position: sticky;
      right: ${overflow.right && 0};
      background: var(--color-background);
      z-index: 1;
    }
  `;
};

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  flex: 1 1 0%;
  padding-bottom: 0.75rem;
  transition: 200ms var(--ease-out-expo);
  grid-template-columns: ${props => handleColumns(props.columns)};
  grid-auto-rows: max-content;
  position: relative;
  overflow-x: auto;
`;
export const SearchContainer = styled.div`
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
  color: var(--color-text-darker);
  input {
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
    color: var(--color-input-text);
    padding: 0.25rem 0.5rem;
    &::placeholder {
      color: var(--color-input-placeholder);
    }
  }
  margin: 0 calc(var(--sideMargin) / 2) calc(var(--sideMargin) / 2);
`;
export const CellContents = styled.div`
  margin: 0.5rem 1rem;
  position: relative;
  width: ${({ stretch }) => (stretch ? '100%' : undefined)};
  ${breakpoints.md} {
    margin: 0.75rem 1.25rem;
  }
`;

export const TableCell = styled.div`
  display: flex;
  align-items: ${props => handleVerticalAlign(props.verticalAlign)};
  justify-content: ${props => handleJustify(props.justify)};
  color: ${props =>
    Number(props.value) === 0
      ? 'var(--color-text-light)'
      : 'var(--color-text-darker)'};

  min-width: ${({ hideOverflow }) => hideOverflow && '0px'};
  overflow: ${({ hideOverflow }) => hideOverflow && 'hidden'};

  text-overflow: ${({ hideOverflow }) => hideOverflow && 'ellipsis'};
  ${CellContents} {
    min-width: ${({ hideOverflow }) => hideOverflow && '0px'};
    text-overflow: ${({ hideOverflow }) => hideOverflow && 'ellipsis'};
    margin: ${({ noMargin }) => noMargin && 0};

  }
  max-width: ${({ maxWidth }) => maxWidth};
  white-space: ${({ allowWrap }) => (allowWrap ? 'normal' : 'nowrap')};


  border-top: solid 1px transparent;
  border-bottom: solid 1px transparent;
  border-color: transparent;

  // position: ${({ sticky }) => (sticky ? 'sticky' : 'relative')};
  // left: ${({ sticky }) => (sticky ? '0' : 'auto')};
  // z-index: ${({ sticky }) => sticky && '100'};

  grid-column: ${({ colWidth }) => colWidth};

  &:last-of-type{
    padding-right:var(--sideMargin);
  }
  &.sum {
    ${CellContents} {
      margin-left: 0;
      font-weight: 700;
    }
    &:before {
      content: '=';
      margin: 0 0.25rem 0 0.75rem;
      display: inline;
      font-weight: 700;
    }
  }

  &.total {
    margin-top: 1rem;
    border-top: 1px solid var(--color-text-lightest);
    font-weight: 700;
  }

  ${handleStickyPositioning}
`;

export const HeaderCellContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: ${props => handleVerticalAlign(props.verticalAlign)};
  user-select: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--font-size-xxs);
  letter-spacing: 0.01em;
  justify-content: ${props => handleJustify(props.justify)};
  cursor: ${props => (props.sortable === true ? 'pointer' : 'auto')};
  white-space: nowrap;
  ${breakpoints.md} {
    font-size: var(--font-size-xs);
  }
  span {
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: -1rem;
  }
`;

// Wrapper element for content row, doesn't affect grid layout in any way
// but lets us add row behavior to table
export const Row = styled.div`
  display: contents;
  &.row-odd > ${TableCell} {
    background-color: var(--color-background-hint);
    &:last-of-type {
      margin-right: var(--sideMargin);
      padding-right: 0;
    }
  }
  &.is-highlighted > ${TableCell} {
    border-top: 1px solid var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
  }
`;

export const HeaderRow = styled.div`
  display: contents;
  ${TableCell} {
    border-bottom: 1px solid var(--color-text-lightest);
    ${breakpoints.md} {
      border-bottom: none;
    }
  }
  ${TableCell}:last-of-type {
    margin-right: var(--sideMargin);
    padding-right: 0;
    padding-left: var(--sideMargin);
    margin-left: calc(-1 * var(--sideMargin));
  }
`;
export const FooterRow = styled.div`
  display: contents;
  ${TableCell}:last-of-type {
    margin-right: var(--sideMargin);
    padding-right: 0;
    padding-left: var(--sideMargin);
    margin-left: calc(-1 * var(--sideMargin));
  }
`;

// Container for on-hover row action buttons
export const RowAction = styled.div`
  opacity: 0;
  transition: opacity 100ms ease-out;

  ${Row}:hover & {
    opacity: 1;
  }

  @media (pointer: coarse) {
    opacity: 1;
  }
`;

export const TableArea = styled.div`
  font-size: var(--font-size-xs);
  position: relative;
  width: calc(100% + 2 * var(--sideMargin));
  max-width: 100vw;
  padding-left: var(--sideMargin);
  margin-left: calc(-1 * var(--sideMargin));
  ${breakpoints.md} {
    font-size: ${({ small }) => !small && 'var(--font-size-sm)'};
  }
`;

export const NoResults = styled.div`
  display: grid;
  grid-column: 1 / -1;
  place-content: center;
  height: 8rem;
  background: var(--color-background-hint);

  font-size: var(--font-size-lg);
  color: var(--color-text-light);
  margin-top: 0.25rem;
  margin-right: var(--sideMargin);
  padding-left: 1rem;
  font-weight: 400;
`;

export const AddRowContainer = styled.div`
  margin-top: 1rem;
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius-sm);
`;

export const RowToAdd = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: var(--font-size-sm);
  border: 1px solid var(--color-background-hint);
  box-shadow: var(--shadow-sm);
  ${breakpoints.lg} {
    padding: 0;
    display: grid;
    grid-template-columns: ${({ columns }) => handleColumns(columns)};
  }
`;

export const StackedCell = styled.div`
  width: 100%;
  padding: 0.5rem;
  ${TableCell} {
    padding: 0;
  }
  ${CellContents} {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  ${breakpoints.xs} {
    width: 50%;
  }
  ${breakpoints.md} {
    width: 33.33%;
  }
  ${breakpoints.lg} {
    width: auto;
    ${CellContents} {
      width: auto;
    }
  }
`;
export const RowToAddActions = styled.div`
  right: 0;
  padding: 0.75rem 0 2rem 0;
  display: flex;
  justify-content: flex-end;
`;

export const Label = styled.span`
  color: var(--color-text-darker);
  white-space: nowrap;
`;
export const AddRowButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  border: 1px solid var(--color-text-lightest);
  color: var(--color-text-dark);
  background-color: transparent;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  &:hover {
    border: 1px solid var(--color-text-lighter);
    color: var(--color-text-darker);
    ${Label} {
      color: var(--color-text-darkest);
    }
  }
  & + & {
    margin-left: 0.5rem;
    ${breakpoints.xl} {
      margin-left: 1rem;
    }
  }

  ${Label} {
    margin-left: 0.5rem;
  }
  &:disabled {
    cursor: not-allowed;
    color: var(--color-text-light);
    ${Label} {
      color: var(--color-text-light);
    }
    &:hover {
      border: 1px solid var(--color-text-lightest);
    }
  }
`;

export const SubHeader = styled.div`
  grid-column: 1 / -1;
  background-color: var(--color-text-lightest);
  padding: 0.65rem 0.5rem;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--color-text-darker);
`;
