import styled from 'styled-components/macro';
import { Button } from 'components/atoms';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: var(--color-text-dark);
`;

export const ButtonContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
`;

export const ConfirmButton = styled(Button)`
  text-transform: none;
  margin: 0 0.5rem;
  background-color: ${({ color }) => color || 'var(--color-primary)'};
  color: #fff;
  &:hover {
    background-color: var(--color-primary-dark);
  }
`;

export const CancelButton = styled(Button)`
  text-transform: none;
  margin: 0 0.5rem;
  background-color: transparent;
  color: var(--color-text-dark);
  border: 1px solid var(--color-text-lightest);
  &:hover {
    color: var(--color-text-darker);
    border: 1px solid var(--color-text-lighter);
    background-color: transparent;
  }
`;
